import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/web-docs/web-docs/src/components/layout/MdxLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const Boxes = makeShortcode("Boxes");
const Box = makeShortcode("Box");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Title mdxType="Title">Documentation</Title>
    <Boxes mdxType="Boxes">
      <Box title="Get started" to="/getstarted" mdxType="Box">
        <p>{`Start with the basic steps of installing Toit, setting up your ESP32 with
the Toit firmware, and deploying a Toit app on your device over-the-air.`}</p>
      </Box>
      <Box title="Tutorials" to="/tutorials" mdxType="Box">
        <p>{`Browse through the tutorials to learn how devices talk to each other in Toit,
and see how to use the different communication mechanisms on your ESP32, such as MQTT and BLE.`}</p>
      </Box>
      <Box title="Peripherals" to="/peripherals" mdxType="Box">
        <p>{`Explore how to work with GPIO in Toit as well as the various peripherals that Toit supports.`}</p>
      </Box>
      <Box title="Language" to="/language" mdxType="Box">
        <p>{`Take the language tour and learn the Toit programming language tailored for embedded devices.
Learn how to adapt Toit packages in your code by utilizing the Toit package registry.`}</p>
      </Box>
      <Box title="Support" to="/support" mdxType="Box">
        <p>{`Support contains a troubleshooting guide, but also links to get in touch with the Toit engineers, if you have any questions or need help.`}</p>
      </Box>
    </Boxes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      